.simpleTable {
    line-height: 1.0;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.simpleTable-elements {
    height: calc(100% - 25px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.simpleTable-pagination {
    margin-bottom: 5px;
    height: 50px;
}

.simpleTable-info {
    font-size: 20px;
    bottom: 0px;
    width: 100%;
    height: 60px;
    text-align: center;
    padding-top: 20px;
}

.simpleTable-arrowLeft {
    cursor: pointer;
    color: #009fe3;
    font-size: 20px;
    position: absolute;
    top: 20px;
    left: 30px;
}

.simpleTable-arrowRight {
    cursor: pointer;
    color: #009fe3;
    font-size: 20px;
    position: absolute;
    top: 20px;
    right: 30px;
}

.simpleTable-arrowLeft .iconDisabled {
    color: grey !important;
    cursor: default !important;
}

.simpleTable-arrowRight .iconDisabled {
    color: grey !important;
    cursor: default !important;
}

.simpleTable .row div p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}