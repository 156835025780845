
body {
    margin: 0;
    padding: 0;
}

html, body, #root {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    color: #58595b;
    font-family: 'source-sans-pro',Calibri,Candara,Segoe,Segoe UI,Optima,Arial,sans-serif;
}

#App {
    width: 100%;
    height: 100%;
}

h1 {
}

h2 {
}

h3 {
}

h4 {
}

a {
}

/* PAGE */

.page {
	padding: 10px 20px;
}

.row {
	padding: 10px 0px;
}

.row-spaced {
	padding-bottom: 60px ;
}

.full-page {
	height: 100%;
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
}

.full-size {
	height: 100%;
	width: 100%;
}

.max-sized-page {
	max-width: 1200px;
	margin: auto;
}

/* BUTTON */

.bottom-right-buttons {
	position: absolute;
	bottom: 30px;
	right: 40px;
}

.bottom-right-buttons > button {
	margin-left: 5px;
}

.bottom-left-buttons {
	position: absolute;
	bottom: 30px;
	left: 40px;
}

.bottom-left-buttons > button {
	margin-left: 5px;
}

.top-right-buttons {
	position: absolute;
	top: 30px;
	right: 20px;
}

.top-right-buttons > button {
	margin-left: 5px;
}

.right-buttons > button {
	margin: 7px 0px;
	float: right;
}

button {
	display:inline-block;
	padding:0.3em 1.2em;
	margin:0 0.1em 0.1em 0;
	border:0.16em solid rgba(0,0,0,0);
	box-sizing: border-box;
	text-decoration:none;
	font-family:'Roboto',sans-serif;
	font-weight:300;
	color:#FFFFFF;
	text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
	text-align:center;
	transition: all 0.2s;
	background-color: #009fe3;
	opacity: .5;
}

button.small-button {
	padding: 0.1em 0.4em !important;
	font-size: 12px;
}

button.full-width-button {
	width: 100%;
	opacity: 1;
    font-variant: small-caps;
    font-size: 20px important;
}

button:hover {
	border-color: rgba(0,0,0,1);
	opacity: 1;
}

button:disabled {
	background-color: grey !important;
	cursor: default !important;
}

@media all and (max-width:30em){
	button {
		display:block;
		margin:0.2em auto;
	}
}

/* BREADCRUMB */

.breadcrumb {
	background: #000;
	color: white !important;
	border-radius: 0;
}

.breadcrumb a {
	color: white !important;
}

.breadcrumb-item+.breadcrumb-item::before {
	color: #fff;
}

/* INPUT */

input, textarea, select {
  position: relative;
}

input, textarea, select {
  display: block;
  margin: 0;
  padding: 5px 12px;
  color: inherit;
  width: 100%;
  font-family: inherit;
  font-size: var(--inputFontSize);
  font-weight: inherit;
  line-height: var(--inputLineHeight);
  border: lightgrey 2px solid;
  border-radius: 0.4rem;
  transition: box-shadow var(--transitionDuration);
  height: 40px;
}

input::placeholder, textarea::placeholder, select::placeholder {
  	color: #B0BEC5;
}

input:focus, textarea:focus, select:focus {
	border: 1px solid #009fe3;
}

/* NOTIFICATION */

.notification-info {
  background-color: #009fe3 !important;
}

.notification-success {
  background-color: #51a351 !important;
}

.notification-warning {
  background-color: #f89406 !important;
}

.notification-error {
  background-color: #e40613 !important;
}